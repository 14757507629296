/* general */
:root {
  --bg-color: #CDC4FB;
  --primary-color: #B8ACF5;
  --secondary-color: #8176B5;
  --text-color: #525252;
  --text-color-2: #8176B5;
  --overlay-color: rgb(12 12 12 / 63%);
  --white: #fff;
  --serif-font: 'Libre Caslon Display', Marcellus, serif;
  --sans-serif-font: 'Segoe UI', 'Lucida Grande', Helvetica, sans-serif;
}
html, body {
  min-height: 100vh;
}
body {
  overflow-x: hidden;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: var(--sans-serif-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--serif-font);
  font-weight: 400;
}
h1{
  font-size: 29px;
}
h3{
  font-size: 18px;
}

p {
  word-break: break-word;
  hyphens: auto;
  font-size: 14px;
}
.cursor__dot div {
  z-index: 999999 !important;
}
.cursor__dot div:last-child {
  background-color: var(--text-color-2) !important;
}
.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

a:not(.btn){
  transition: .35s all;
  color: var(--text-color);
}
a:not(.btn):hover{
  color: var(--secondary-color);
}

.btn {
  border-radius: 50px;
  padding: 4px 19px;
  color: var(--secondary-color);
  position: relative;
  border: var(--secondary-color) 2px solid;
  overflow: hidden;
  transition: all .35s;
  cursor: pointer;
}

.btn span {
  position: relative;
  z-index: 2;
}

.btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--text-color-2);
  transition: all .35s;
}

.btn:hover {
  color: var(--white);
  border: var(--text-color-2) 2px solid;
}

.btn:hover:after {
  width: 100%;
}
/* loader */
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  margin: auto;
  aspect-ratio: 1;
  --_c:no-repeat radial-gradient(farthest-side,var(--primary-color) 92%,#0000);
  background: 
    var(--_c) top,
    var(--_c) left,
    var(--_c) right,
    var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}
@keyframes l7 {to{transform: rotate(.5turn)}}

/* home */
blockquote{
  white-space: break-spaces;
  width: 100%;
  font-size: 1.1rem;
}
.search_area{ top: 1.5rem; }
.reveal{ color: var(--secondary-color); }
#search_results .item{
  padding: 1rem;
  background: var(--white);
  transition: .35s all;
  border-top: 1px solid var(--bs-border-color);
}
#search_results .item:hover{
  filter: brightness(90%);
}

.song_card > div{
  background: var(--white);
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
}
.song_card .image{
  width: 130px;
}
.song_card .image img{
  width: 100%;
  height: 100%;
}
.song_card .content{
  padding-left: 3%;
  width: calc( 100% - 130px );
  flex-direction: column;
}

/* header */
header svg{ max-width: 110px; }
header svg .cls-1{
  fill:none;
  stroke: var(--secondary-color);
  stroke-miterlimit: 10;
  stroke-width: 8px;
}
header svg .cls-2{
  font-size: 130px;
}
header svg .cls-2, header svg .cls-3{
  fill: var(--secondary-color);
  font-family: var(--serif-font);
  font-weight: 400;
}
header svg .cls-3{
  font-size: 60px;
}
footer p{ font-size: 12px; }

.form-floating label{
  opacity: .6;
}
.form-floating input{ border: none; }
.form-floating .submit{
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 16px;
  background: transparent;
  border: none;
  box-shadow: none;
  opacity: .6;
}

@media all and (min-width: 640px){

}
@media all and (min-width: 1024px){
  header svg{
    max-width: 160px;
  }
  h1{
    font-size: 40px;
  }
  h3{
    font-size: 25px;
  }
  
}